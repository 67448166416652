// Define your custom variables
$primary: #FF0090;
$secondary: white;
$btn-border-radius: 8;
$theme-colors-text: #333333;
$body-bg: rgba(255, 255, 255, 0);
$custom_blue: rgba(6, 17, 97, 81%);
// Import Bootstrap SCSS files
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/buttons"; // Import the default Bootstrap button styles
@import "~bootstrap/scss/bootstrap";

// Override Bootstrap button styles with your custom variables
.btn-primary {
  background: linear-gradient(132deg, $primary, $custom_blue 88%) !important;
  border-color: $primary;
  border-radius: $btn-border-radius;
  color: $secondary !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: $secondary !important;
  background-color: darken($primary, 10%) !important;
  border-color: darken($primary, 10%) !important;
}

.hero_gradient {
  background: linear-gradient(132deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88), rgba(6, 17, 97, 81%) 88%);
}

.text_gradient {
  background: linear-gradient(132deg, $primary, $custom_blue 88%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

// Override Bootstrap variables
:root {
  --bs-secondary-color: $theme-colors-text !important;
  --bs-emphasis-color: $theme-colors-text !important;
  --bs-body-font-family: 'Montserrat', sans-serif;
  --bs-body-font-size: 16px;
  --bs-body-font-weight: normal;
  --bs-body-line-height: 1.5;
  --bs-body-color: #333; /* Your custom text color */
  --bs-body-text-align: left;
  --bs-body-bg: #fff; /* Your custom background color */
}

.h1 {
  font-size: min(40px, 4rem) !important;
}

body {
  background-image: url("../../public/assets/background.png");
  background-size: contain;
}

.shadow-sm {
  box-shadow: 0 24px 30px rgba(0, 31, 255, 0.05) !important;
}

.card {
  border: none;
}


.bg-light {
  background-color: rgba(245, 245, 245, 0.97) !important
}

.hero-header {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.hero-header .hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.hero-header .hero-container {
  position: relative;
  z-index: 2;
}
.hero-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}



@media (pointer: coarse) and (hover: none) {
  .hero-header {
    background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  }

  .hero-header .hero-video {
    display: none;
  }
}



