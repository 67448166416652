.custom-navbar {
    transition: top 0.3s ease-in-out;
}

.custom-navbar.invisible {
    top: -100px;
}

.custom-navbar.visible {
    top: 0;
}